import React from 'react';
import { ChakraProvider, Box, Container, Heading, Grid, GridItem } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import IncidentDashboard from './components/IncidentDashboard';
import ModelTraining from './components/ModelTraining';
import Prediction from './components/Prediction';
import ParticlesBackground from './components/ParticlesBackground';
import './index.css';
import { Leva } from 'leva';

const theme = extendTheme({
    colors: {
        primary: {
            500: '#1976d2',
        },
        secondary: {
            500: '#dc004e',
        },
    },
    fonts: {
        heading: 'Inter, Arial, sans-serif',
        body: 'Inter, Arial, sans-serif',
    },
});

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Leva />
            <ParticlesBackground />
            <Canvas style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
                <OrbitControls />
                <Stars />
            </Canvas>
            <Container maxW="container.lg" py={4} style={{ position: 'relative', zIndex: 1 }}>
                <Box
                    textAlign="center"
                    py={10}
                    bgGradient="linear(to-r, #6a11cb, #2575fc)"
                    color="white"
                    borderRadius="md"
                    className="shadow-lg"
                >
                    <Heading as="h1" size="2xl">Cyber Incident Dashboard</Heading>
                </Box>
                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={6}>
                    <GridItem>
                        <Box p={4} bg="white" borderRadius="md" className="shadow-lg">
                            <IncidentDashboard />
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box p={4} bg="white" borderRadius="md" className="shadow-lg">
                            <ModelTraining />
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box p={4} bg="white" borderRadius="md" className="shadow-lg">
                            <Prediction />
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </ChakraProvider>
    );
}

export default App;