import React, { useState } from 'react';
import { Box, Button, Text, Textarea, Heading, VStack, List, ListItem } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useSound from 'use-sound';
import clickSound from '../sounds/click.mp3'; // Add your sound file here

function Prediction() {
    const [inputData, setInputData] = useState('');
    const [predictions, setPredictions] = useState([]);
    const [error, setError] = useState('');
    const [playClick] = useSound(clickSound);

    const handlePrediction = () => {
        playClick();
        try {
            const parsedData = JSON.parse(inputData);
            fetch('/api/predict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parsedData),
            })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    setPredictions([]);
                } else {
                    setPredictions(data.predictions);
                    setError('');
                }
            })
            .catch(error => setError('Error: ' + error.message));
        } catch (e) {
            setError('Error: Invalid JSON format');
        }
    };

    return (
        <Box>
            <Heading as="h2" size="lg" mb={4}>Make Predictions</Heading>
            <VStack align="start">
                <Textarea
                    placeholder="Input Data"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    size="md"
                />
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Button colorScheme="blue" onClick={handlePrediction}>Predict</Button>
                </motion.div>
                {error && <Text color="red.500">{error}</Text>}
                {predictions.length > 0 && (
                    <Box mt={4}>
                        <Heading as="h3" size="md">Predictions:</Heading>
                        <List spacing={2}>
                            {predictions.map((prediction, index) => (
                                <ListItem key={index}>
                                    <Text>{prediction}</Text>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
            </VStack>
        </Box>
    );
}

export default Prediction;