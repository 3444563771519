import React, { useEffect, useState } from 'react';
import { Box, Heading, List, ListItem, Link, Button, CircularProgress, Text, VStack, HStack, Select, Input, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { marked } from 'marked';
import create from 'zustand';

// Zustand store for managing state
const useStore = create(set => ({
    incidents: [],
    setIncidents: (incidents) => set({ incidents }),
    analysis: {},
    setAnalysis: (index, analysis) => set(state => ({ analysis: { ...state.analysis, [index]: analysis } })),
    loading: {},
    setLoading: (index, loading) => set(state => ({ loading: { ...state.loading, [index]: loading } })),
}));

function IncidentDashboard() {
    const { incidents, setIncidents, analysis, setAnalysis, loading, setLoading } = useStore();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentAnalysis, setCurrentAnalysis] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [customSearch, setCustomSearch] = useState('');

    const fetchIncidents = (query) => {
        fetch(`https://backend.sih.akshatmehta.com/api/incidents?query=${query}`)
            .then(response => response.json())
            .then(data => setIncidents(data));
    };

    const handleAnalyze = (snippet, index) => {
        setLoading(index, true);
        fetch('https://backend.sih.akshatmehta.com/api/analyze', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ snippet }),
        })
        .then(response => response.json())
        .then(data => {
            setAnalysis(index, data.analysis);
            setCurrentAnalysis(data.analysis);
            setLoading(index, false);
            onOpen();
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(index, false);
        });
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        setCustomSearch('');
        fetchIncidents(`cyber attack India ${event.target.value}`);
    };

    const handleCustomSearch = () => {
        if (customSearch.trim() !== '') {
            setSelectedYear('');
            fetchIncidents(customSearch);
        }
    };

    return (
        <Box>
            <Heading as="h2" size="lg" mb={4}>Recent Cyber Incidents</Heading>
            <HStack spacing={4} mb={4}>
                <Select placeholder="Select Year" value={selectedYear} onChange={handleYearChange}>
                    {Array.from({ length: new Date().getFullYear() - 2017 }, (_, i) => 2018 + i).map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </Select>
                <Input
                    placeholder="Custom Search"
                    value={customSearch}
                    onChange={(e) => setCustomSearch(e.target.value)}
                />
                <Button colorScheme="blue" onClick={handleCustomSearch}>Search</Button>
            </HStack>
            <List spacing={4}>
                {incidents.map((incident, index) => (
                    <ListItem key={index} p={4} bg="gray.50" borderRadius="md" className="shadow-md">
                        <VStack align="start" spacing={3}>
                            <Text fontWeight="bold">{incident.title}</Text>
                            <Text>{incident.snippet}</Text>
                            <Link href={incident.link} isExternal color="blue.500">Read more</Link>
                            <HStack spacing={3} width="100%">
                                <Button colorScheme="blue" onClick={() => handleAnalyze(incident.snippet, index)}>Gemini Analyze</Button>
                                {loading[index] && (
                                    <HStack spacing={2}>
                                        <CircularProgress isIndeterminate color="blue.500" size="24px" />
                                        <Text>Generating your response...</Text>
                                    </HStack>
                                )}
                            </HStack>
                        </VStack>
                    </ListItem>
                ))}
            </List>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Analysis Result</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box dangerouslySetInnerHTML={{ __html: marked(currentAnalysis) }} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default IncidentDashboard;
