import React, { useState } from 'react';
import { Box, Button, Text, Textarea, Heading, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useSound from 'use-sound';
import clickSound from '../sounds/click.mp3'; // Add your sound file here

function ModelTraining() {
    const [trainingData, setTrainingData] = useState('');
    const [message, setMessage] = useState('');
    const [playClick] = useSound(clickSound);

    const handleTraining = () => {
        playClick();
        try {
            const parsedData = JSON.parse(trainingData);
            fetch('/api/train', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parsedData),
            })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setMessage('Error: ' + data.error);
                } else {
                    setMessage(data.message);
                }
            })
            .catch(error => setMessage('Error: ' + error.message));
        } catch (e) {
            setMessage('Error: Invalid JSON format');
        }
    };

    return (
        <Box>
            <Heading as="h2" size="lg" mb={4}>Model Training</Heading>
            <VStack align="start">
                <Textarea
                    placeholder="Training Data"
                    value={trainingData}
                    onChange={(e) => setTrainingData(e.target.value)}
                    size="md"
                />
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Button colorScheme="blue" onClick={handleTraining}>Train Model</Button>
                </motion.div>
                {message && <Text color="gray.500">{message}</Text>}
            </VStack>
        </Box>
    );
}

export default ModelTraining;
